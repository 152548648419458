<template>
    <div class="content-wrapper">
        <PageHeader :screenName="pageTitle" :linkItems="linkItems"/>

        <main class="card p-2" :class="{'opacity-50': loadingData}">
            <section class="infos mb-4">
                <b-row class="mb-2">
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Nome do Departamento Regional</label>
                        <p class="font-size-14">{{ departamento.name }}</p>
                    </b-col>
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Estado</label>
                        <p class="font-size-14">{{ departamento.uf }} - {{ departamento.state }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2">
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Sigla do Departamento Regional</label>
                        <p class="font-size-14">{{ departamento.acronym }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">Responsável por este Departamento Regional*</label>
                        <p v-if="departamento.nomeResponsavel" class="font-size-14">{{ departamento.nomeResponsavel }}</p>
                        <p v-if="!departamento.nomeResponsavel" class="text-danger">-</p>
                    </b-col>
                    <b-col>
                        <label class="font-weight-bold mb-0 font-size-15">E-mail*</label>
                         <p v-if="departamento.emailResponsavel" class="font-size-14">{{ departamento.emailResponsavel }}</p>
                        <p v-if="!departamento.emailResponsavel" class="text-danger">-</p>
                    </b-col>
                </b-row>
            </section>
            <hr>
            <RegiaoForm
                :key="keyRegions"
                :regionDevide="departamento.divisao_por_regiao"
                :idDepartamento="idDepartamento"
                :edit="false"
            />
            <section v-if="canEdit" class="buttons">
                <b-button id="redirect-edit" 
                    class="mr-2" 
                    variant="custom-blue"
                    @click.prevent="redirectToEdit"
                    :disabled="loadingData"
                >
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Editar</span>
                </b-button>
            </section>
        </main>
    </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BRow, BCol, BButton } from 'bootstrap-vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import RegiaoForm from './Regiao/RegiaoForm.vue';
import { getInfoUserDepartamento } from "@core/utils/store/getStore";

export default {
    title: 'Detalhes departamento',

    components: {
        PageHeader, BRow, BCol, BButton,
        RegiaoForm
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Departamentos regionais',
                    routeName: 'departamento-list'
                },
                {
                    name: '',
                    active: true
                }
            ],
            pageTitle: 'Visualizar DR',
            loadingData: true,
            departamento: {
                name: null,
                uf: null,
                state: null,
                acronym: null,
                nomeResponsavel: null,
                emailResponsavel: null,
                divisao_por_regiao: null
            },
            idDepartamento: null,
            acronymDepartamento: null,
            canEdit: false,
            userData: null,
            editDepartamentoRegiao: false,
            keyRegions: 0
        }
    },

    mounted() {
        if(
            (!this.$route.params.idDepartamento && !getInfoUserDepartamento('id_departamento')) ||
            (this.$route.params.idDepartamento == 1 && getInfoUserDepartamento('id_departamento') == 1)
        ) { this.redirectToList(); }
        
        this.userData = this.$store.state.sessions.userData

        let idDepartamento = null,
            acronymDepartamento = null;

        if (this.$route.params.idDepartamento) {
            idDepartamento = this.$route.params.idDepartamento 
            acronymDepartamento = this.$route.params.acronymDepartamento
        }
        else {
            idDepartamento = this.userData.departamento.id_departamento;
            acronymDepartamento = this.userData.departamento.sigla;
            this.editDepartamentoRegiao = true;
        }

        localStorage.setItem('departamentoView', JSON.stringify({
                idDepartamento : idDepartamento, 
                acronymDepartamento : acronymDepartamento
            }));

        this.idDepartamento = idDepartamento;
        this.acronymDepartamento = acronymDepartamento;

        this.configHeader(this.acronymDepartamento);
        this.loadDataDepartamento();

        this.canEdit = this.verifyPermissions(actions.ATUALIZAR)
    },

    methods: {
        loadDataDepartamento() {
            this.$http.get(this.$api.departamentoParameter(this.idDepartamento)).then(({ data }) => {
                this.departamento.name = data.descricao;
                this.departamento.uf = data.uf;
                this.departamento.state = data.estado;
                this.departamento.acronym = data.sigla;
                this.departamento.nomeResponsavel = data.responsavel_nome;
                this.departamento.emailResponsavel = data.responsavel_email;
                this.departamento.divisao_por_regiao = data.divisao_por_regiao;
                this.keyRegions =  Math.random();

                this.loadingData = false;
            }).catch(() => {
                this.redirectToList();
            });
        },

        configHeader(acronym) {
            this.pageTitle = acronym;

            if(this.$route.meta.linkItems) {
                this.linkItems = this.$route.meta.linkItems;
                this.linkItems[0].name = acronym;
                return;
            }
            this.linkItems[1].name = acronym;
        },

        redirectToList() {
            this.$router.push({ name: 'departamento-list' });
        },

        redirectToEdit() {
            let routeName = 'departamento-edit';
            let editRegiao = false;

            if(this.editDepartamentoRegiao) {
                routeName = 'departamento-edit-regiao'
                editRegiao = true;
            }

            this.$router.push(
                { 
                    name: routeName, 
                    params: {
                        idDepartamento: this.idDepartamento, 
                        acronymDepartamento: this.acronymDepartamento,
                        editRegiao
                    }
                }
            );
        },

        verifyPermissions(action) {
            const permissionDN = this.$can(actions[action], subjects.DEPARTAMENTO)
            const permissionDR = this.$can(actions[action], subjects.REGIAO)

            return [permissionDN, permissionDR].includes(true);
        }
    }
}
</script>

